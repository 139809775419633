import React from 'react'
import MUITextField, { FilledTextFieldProps } from '@material-ui/core/TextField'

import useStyles from './styles'

type Props = FilledTextFieldProps;

const TextField = (props: Props) => {
  const classes = useStyles({ error: props.error })
  return (
    <MUITextField
      color="secondary"
      variant="filled"
      fullWidth
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.input,
          focused: classes.focused
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focusedLabel
        }
      }}
      {...props}
    />
  )
}

export default TextField
